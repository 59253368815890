import * as THREE from 'three';
import {
	ACESFilmicToneMapping,
	NoToneMapping,
	Box3,
	LoadingManager,
	Sphere,
	DoubleSide,
	Mesh,
	MeshStandardMaterial,
	PlaneGeometry,
	Group,
	MeshPhysicalMaterial,
	WebGLRenderer,
	Scene,
	PerspectiveCamera,
	OrthographicCamera,
	MeshBasicMaterial,
	sRGBEncoding,
	CustomBlending,
} from 'three';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { LDrawLoader } from 'three/examples/jsm/loaders/LDrawLoader.js';
import { LDrawUtils } from 'three/examples/jsm/utils/LDrawUtils.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { generateRadialFloorTexture } from './utils/generateRadialFloorTexture.js';
import { PathTracingSceneWorker } from '../src/workers/PathTracingSceneWorker.js';
import { DynamicPathTracingSceneGenerator, PhysicalPathTracingMaterial, PathTracingRenderer, MaterialReducer, BlurredEnvMapGenerator, GradientEquirectTexture, EquirectCamera, DenoiseMaterial } from '../src/index.js';
import { FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

var resultFLO;
let model;
const group = new Group();

const envMaps = {
	'Oceanflo': 'https://adn3dboat.fr/ASVs11/kloppenheim_06_4k.hdr',
	'OceanSunset': 'https://adn3dboat.fr/ASVs11/kloppenheim_06_puresky_2k.hdr',
	'Nuit': 'https://adn3dboat.fr/ASVs11/moonless_golf_2k.hdr',
	'Crique': 'https://adn3dboat.fr/ASVs11/cannon_2k.hdr',
	'Small': 'https://adn3dboat.fr/ASVs11/montainflo.hdr',
	'Rock': 'https://adn3dboat.fr/ASVs11/mountain.hdr',
	'BordOcean': 'https://adn3dboat.fr/ASVs11/table_mountain_1_puresky_2k.hdr',
	'snow2': 'https://adn3dboat.fr/ASVs11/quarry_03_2k.hdr',
	'chantier': 'https://adn3dboat.fr/ASVs11/industrial_pipe_and_valve_01_2k.hdr',
	'Circus Arena': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/circus_arena_1k.hdr',
	'Chinese Garden': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/chinese_garden_1k.hdr',
	'Autoshop': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/autoshop_01_1k.hdr',

	'Measuring Lab': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/vintage_measuring_lab_2k.hdr',
	'Whale Skeleton': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/whale_skeleton_2k.hdr',
	'Hall of Mammals': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/hall_of_mammals_2k.hdr',
};

const models = window.MODEL_LIST || {};

const matcoque = new THREE.MeshPhysicalMaterial({});
const matcoquebas = new THREE.MeshPhysicalMaterial({transparent:0,side: DoubleSide,});
			
const matcoquehaut = new THREE.MeshPhysicalMaterial({transparent:0,side: DoubleSide,});
const matGELARR = new THREE.MeshPhysicalMaterial({transparent:0,side: DoubleSide,});
const matGELAva = new THREE.MeshPhysicalMaterial({transparent:0,side: DoubleSide,});
var out;
var masaturation;
var Peinture;	
var couleurMemoire;	
var fullscreenADN = new Boolean(0);
var ledall = new Boolean(0);
var ouvre = new Boolean(0);
var metal = new Boolean(0);
const texteck = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/v1noir.jpg" );	
const norteck = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/nor.jpg" );	
const teck = new THREE.MeshPhysicalMaterial({
							map: texteck,
							//normalMap : norteck,
	  						roughness : 0.6,
	  						side: DoubleSide,
											});
	  

const teckailehaut = new THREE.MeshPhysicalMaterial({
							map: texteck,
							//normalMap : norteck,
	  						roughness : 0.6,
	  						side: DoubleSide,
											});
const teckailebas = new THREE.MeshPhysicalMaterial({
							map: texteck,
							transparent : 0, 
							opacity : 0,
							//normalMap : norteck,
	  						roughness : 0.6,
	  						side: DoubleSide,
											});

const aluailebas = new THREE.MeshPhysicalMaterial({
							
							transparent : 0, 
							opacity : 0,
							metalness : 1,
	  						roughness : 0.0,
	  						side: DoubleSide,
											});
const aluailehaut = new THREE.MeshPhysicalMaterial({
							
							transparent : 1, 
							opacity : 1,
							metalness : 1,
							roughness : 0.0,
	  						side: DoubleSide,
											});
const ledmat = new THREE.MeshPhysicalMaterial({opacity : 1,
											 transparent : true,
											 side : DoubleSide,});
const ledsousO = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/led2.png" );
		ledsousO.repeat.y = - 1;


const ledSMmat = new THREE.MeshStandardMaterial({

	map: ledsousO,
	emissiveMap : ledsousO,
	emissive:'#e0e0e0',
	emissiveIntensity:1.5,
	transparent : true,
	blending:1,
	roughness:1,
	opacity : 1,
	side : DoubleSide,

});

//////////////TEXTURE DE LA SELLERIE POUR MISE EN TAMPON
/////////////COLLECTION DIAMANTE
const texSellerieNor = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/DIAMNOR2.jpg" );

const texSellerie = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/SUNSET.jpg" );
const texSNOW = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/SNOW.jpg" );
const texINDIGO = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/INDIGO.jpg" );
const texTAUPE = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/TAUPE.png" );
const texIVORY = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/IVORY.jpg" );
const texTONI = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/TONI.jpg" );
const texLAVA = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/LAVA.jpg" );
const texCARBON = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/sellerie/diamante/CARBON.jpg" );

const teckv1noir = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/teck/v1noir.jpg" );
const teckGrisBlanc = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/teck/GrisBlanc.png" );
const teckTeckBlanc = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/teck/TeckBlanc.png" );
const teckGrisNoir = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/teck/GrisNoir.png" );

const sellerieindigo = new THREE.MeshPhysicalMaterial({
		color : '#e79f67',
		map : texSellerie,
		//bumpMap : texSNOW,
		normalMap : texSellerieNor,
		//specularIntensityMap : texCARBON,
		//specularColorMap  : texCARBON,
	    roughness : 0.99,
	});
let container;

let initialModel = Object.keys( models )[ 0 ];
if ( window.location.hash ) {

	const modelName = window.location.hash.substring( 1 ).replaceAll( '%20', ' ' );
	if ( modelName in models ) {

		initialModel = modelName;

	}

}

const params = {
	

	multipleImportanceSampling: false, // a désactiver sur IOS
	acesToneMapping: true,
	resolutionScale: 1 / window.devicePixelRatio,
	tilesX: 2,
	tilesY: 2,
	samplesPerFrame: 1,

	model: initialModel,

	envMap: envMaps[ 'OceanSunset' ],

	gradientTop: '#bfd8ff',
	gradientBottom: '#ffffff',

	environmentIntensity: 1.0,
	environmentBlur: 0.0,
	environmentRotation: 180,

	cameraProjection: 'Perspective',

	backgroundType: 'Environment',
	bgGradientTop: '#111111',
	bgGradientBottom: '#000000',
	backgroundAlpha: 1.0,
	checkerboardTransparency: true,

	enable: true,
	bounces: 3,
	filterGlossyFactor: 0.5,
	pause: false,

	floorColor: '#080808',
	floorOpacity: 1.0,
	floorRoughness: 0.1,
	floorMetalness: 0.0,

	animflo: false,
	matcoque: {//////////////////couleur COQUE
		color: '#0d98ba',
		emissive: '#000000',
		emissiveIntensity: 1,
		roughness: 0.3,
		metalness: 0.8,
		ior: 1.495,
		transmission: 0.0,
		thinFilm: false,
		attenuationColor: '#ffffff',
		attenuationDistance: 0.5,
		opacity: 1.0,
		clearcoat: 0.0,
		clearcoatRoughness: 0.0,
		sheenColor: '#000000',
		sheenRoughness: 0.0,
		iridescence: 0.0,
		iridescenceIOR: 1.5,
		iridescenceThickness: 400,
		specularColor: '#ffffff',
		specularIntensity: 1.0,
		matte: false,
		flatShading: false,
		castShadow: true,

	},
	matGELARR: {/////////////////////couleur Gel coat ARRIERE
		color: '#ffffff',
		emissive: '#000000',
		emissiveIntensity: 1,
		roughness: 0.3,
		metalness: 0.8,
		ior: 1.495,
		transmission: 0.0,
		thinFilm: false,
		attenuationColor: '#ffffff',
		attenuationDistance: 0.5,
		opacity: 1.0,
		clearcoat: 0.0,
		clearcoatRoughness: 0.0,
		sheenColor: '#000000',
		sheenRoughness: 0.0,
		iridescence: 0.0,
		iridescenceIOR: 1.5,
		iridescenceThickness: 400,
		specularColor: '#ffffff',
		specularIntensity: 1.0,
		matte: false,
		flatShading: false,
		castShadow: true,

	},
	matGELAva: {/////////////////////couleur Gel coat ARRIERE
		color: '#ffffff',
		emissive: '#000000',
		emissiveIntensity: 1,
		roughness: 0.3,
		metalness: 0.8,
		ior: 1.495,
		transmission: 0.0,
		thinFilm: false,
		attenuationColor: '#ffffff',
		attenuationDistance: 0.5,
		opacity: 1.0,
		clearcoat: 0.0,
		clearcoatRoughness: 0.0,
		sheenColor: '#000000',
		sheenRoughness: 0.0,
		iridescence: 0.0,
		iridescenceIOR: 1.5,
		iridescenceThickness: 400,
		specularColor: '#ffffff',
		specularIntensity: 1.0,
		matte: false,
		flatShading: false,
		castShadow: true,

	},

	ledmat: {           //LED
		name: 'led',
		color: '#ffffff',
		emissive: '#000000',
		emissiveIntensity: 0,
		opacity:0,
		},
	ledSMmat:{
		opacity:1,
	},
	sellerieindigo:{
		color: '#3d767e',
		map: texINDIGO,
		roughness: 0.99,
	},
	teck:{
		map: texteck,
	},
	teckhaut:{
		map: texteck,
	},
	teckbas:{
		map: texteck,
	},
	matcoquebas: {           //coqueBas
				opacity: 0.0,
			},
	matcoquehaut: {           //coqueBas
				opacity: 1.0,
			},
	ouvert :false,

};//end params


let creditEl, loadingEl, samplesEl;
let floorPlane, gui, stats, sceneInfo;
let renderer, orthoCamera, perspectiveCamera, activeCamera;
let ptRenderer, fsQuad, controls, scene;
let envMap, envMapGenerator, backgroundMap;
let loadingModel = false;
let delaySamples = 0;

const orthoWidth = 2;

init();

async function init() {

	creditEl = document.getElementById( 'credits' );
	loadingEl = document.getElementById( 'loading' );
	samplesEl = document.getElementById( 'samples' );



	renderer = new WebGLRenderer( { antialias: true } );
	renderer.outputEncoding = sRGBEncoding;
	renderer.toneMapping = ACESFilmicToneMapping;
	//document.body.appendChild( renderer.domElement );
	container = document.getElementById('container');
	container.appendChild( renderer.domElement);

	scene = new Scene();

	const aspect = window.innerWidth / window.innerHeight;
	perspectiveCamera = new PerspectiveCamera( 30, aspect, 0.025, 500 );
	perspectiveCamera.position.set( 0.69, -0.0134, 0.9220577);//x,y,z init camera 

	perspectiveCamera.maxZoom = 0.4;
	const orthoHeight = orthoWidth / aspect;
	orthoCamera = new OrthographicCamera( orthoWidth / - 2, orthoWidth / 2, orthoHeight / 2, orthoHeight / - 2, 0, 100 );
	orthoCamera.position.set( - 0.5, 0.15, 1 );

	backgroundMap = new GradientEquirectTexture();
	backgroundMap.topColor.set( params.bgGradientTop );
	backgroundMap.bottomColor.set( params.bgGradientBottom );
	backgroundMap.update();

	ptRenderer = new PathTracingRenderer( renderer );
	ptRenderer.alpha = true;
	ptRenderer.material = new PhysicalPathTracingMaterial();
	ptRenderer.tiles.set( params.tiles, params.tiles );
	ptRenderer.material.setDefine( 'FEATURE_MIS', Number( params.multipleImportanceSampling ) );
	//ptRenderer.material.setDefine( 'FEATURE_MIS', Number() );
	ptRenderer.material.backgroundMap = backgroundMap;

	fsQuad = new FullScreenQuad( new MeshBasicMaterial( {
		map: ptRenderer.target.texture,
		blending: CustomBlending,
		premultipliedAlpha: renderer.getContextAttributes().premultipliedAlpha,
	} ) );

	controls = new OrbitControls( perspectiveCamera, renderer.domElement );
	controls.addEventListener( 'change', resetRenderer );
	controls.target.set(0.10, 0.00, 0);
	controls.maxPolarAngle = Math.PI / 1.96; // Limit angle of visibility
    controls.keys = {
          LEFT: 37, //left arrow
          UP: 38, // up arrow
          RIGHT: 39, // right arrow
          BOTTOM: 40 // down arrow
        };
	envMapGenerator = new BlurredEnvMapGenerator( renderer );

	const floorTex = generateRadialFloorTexture( 2048 );
	floorTex.repeat.set( 1, 1 );
	const texturew = new THREE.TextureLoader().load( "https://adn3dboat.fr/ASVs11/BVB2PQT.png" );
	texturew.wrapS = THREE.RepeatWrapping;
	texturew.wrapT = THREE.RepeatWrapping;
	texturew.repeat.set( 80, 60 );
	floorPlane = new Mesh(
		new PlaneGeometry(),
		new THREE.MeshPhysicalMaterial( {
			map: floorTex,
			normalMap:  texturew,
			transparent: true,
			transmission:1,
			thickness: 0.01,
			color: 0x00DDFF,
			ior: 1.333,
			roughness: 0.1,
			metalness: 0.0,
			side: DoubleSide,
		} )
	);
	floorPlane.scale.setScalar( 100 );
	floorPlane.rotation.x = - Math.PI / 2;
	

	stats = new Stats();
	//container.appendChild( stats.dom );
	renderer.physicallyCorrectLights = true;
	renderer.toneMapping = ACESFilmicToneMapping;
	scene.background = backgroundMap;
	ptRenderer.tiles.set( params.tilesX, params.tilesY );

	
//	$('#BandeCouleur').fadeOut();
	//$('#BandeBasCoque').fadeOut();
	//$('#BandeBasDiamente').fadeOut();
	

	updateCamera( params.cameraProjection );
	updateModel();
	updateEnvMap();
	onResize();

	animate();

	window.addEventListener( 'resize', onResize );

}



function animate() {

	requestAnimationFrame( animate );

	stats.update();

	if ( loadingModel ) {

		return;

	}

	console.log("cam = ");
	console.log(perspectiveCamera.position);
	console.log("target = ");
 console.log(controls.target);

	const sell = sellerieindigo;
	sell.color.set(params.sellerieindigo.color).convertSRGBToLinear();
	sell.map = params.sellerieindigo.map;
	sell.bumpMap = texTAUPE;
	sell.normalMap = texSellerieNor;
	sell.roughness= 0.9;
		


	const m1 = matcoque;//coque
	matcoque.color.set( params.matcoque.color ).convertSRGBToLinear();
	m1.emissive.set( params.matcoque.emissive ).convertSRGBToLinear();
	m1.emissiveIntensity = params.matcoque.emissiveIntensity;
	m1.metalness = params.matcoque.metalness;
	m1.roughness = params.matcoque.roughness;
	m1.transmission = params.matcoque.transmission;
	m1.attenuationDistance = params.matcoque.thinFilm ? Infinity : params.matcoque.attenuationDistance;
	m1.attenuationColor.set( params.matcoque.attenuationColor );
	m1.ior = params.matcoque.ior;
	m1.opacity = params.matcoque.opacity;
	m1.clearcoat = params.matcoque.clearcoat;
	m1.clearcoatRoughness = params.matcoque.clearcoatRoughness;
	m1.sheenColor.set( params.matcoque.sheenColor ).convertSRGBToLinear();
	m1.sheenRoughness = params.matcoque.sheenRoughness;
	m1.iridescence = params.matcoque.iridescence;
	m1.iridescenceIOR = params.matcoque.iridescenceIOR;
	m1.iridescenceThicknessRange = [ 0, params.matcoque.iridescenceThickness ];
	m1.specularColor.set( params.matcoque.specularColor ).convertSRGBToLinear();
	m1.specularIntensity = params.matcoque.specularIntensity;
	m1.transparent = m1.opacity < 1;
	m1.flatShading = params.matcoque.flatShading;
	
	const GelAR = matGELARR;
	GelAR.color.set(params.matGELARR.color).convertSRGBToLinear();
	GelAR.emissive.set( params.matGELARR.emissive ).convertSRGBToLinear();
	GelAR.emissiveIntensity = params.matGELARR.emissiveIntensity;
	GelAR.metalness = params.matcoque.metalness - 0.5;
	GelAR.roughness = params.matGELARR.roughness;
	GelAR.transmission = params.matGELARR.transmission;
	GelAR.attenuationDistance = params.matGELARR.thinFilm ? Infinity : params.matGELARR.attenuationDistance;
	GelAR.attenuationColor.set( params.matGELARR.attenuationColor );
	GelAR.ior = params.matGELARR.ior;
	GelAR.opacity = params.matGELARR.opacity;
	GelAR.clearcoat = params.matGELARR.clearcoat;
	GelAR.clearcoatRoughness = params.matGELARR.clearcoatRoughness;
	GelAR.sheenColor.set( params.matGELARR.sheenColor ).convertSRGBToLinear();
	GelAR.sheenRoughness = params.matGELARR.sheenRoughness;
	GelAR.iridescence = params.matGELARR.iridescence;
	GelAR.iridescenceIOR = params.matGELARR.iridescenceIOR;
	GelAR.iridescenceThicknessRange = [ 0, params.matGELARR.iridescenceThickness ];
	GelAR.specularColor.set( params.matGELARR.specularColor ).convertSRGBToLinear();
	GelAR.specularIntensity = params.matGELARR.specularIntensity;
	GelAR.transparent = GelAR.opacity < 1;
	GelAR.flatShading = params.matGELARR.flatShading;

	const GelAV = matGELAva;
	GelAV.color.set(params.matGELAva.color).convertSRGBToLinear();
	GelAV.emissive.set( params.matGELAva.emissive ).convertSRGBToLinear();
	GelAV.emissiveIntensity = params.matGELAva.emissiveIntensity;
	GelAV.metalness = params.matcoque.metalness- 0.5;
	GelAV.roughness = params.matGELAva.roughness;
	GelAV.transmission = params.matGELAva.transmission;
	GelAV.attenuationDistance = params.matGELAva.thinFilm ? Infinity : params.matGELAva.attenuationDistance;
	GelAV.attenuationColor.set( params.matGELAva.attenuationColor );
	GelAV.ior = params.matGELAva.ior;
	GelAV.opacity = params.matGELAva.opacity;
	GelAV.clearcoat = params.matGELAva.clearcoat;
	GelAV.clearcoatRoughness = params.matGELAva.clearcoatRoughness;
	GelAV.sheenColor.set( params.matGELAva.sheenColor ).convertSRGBToLinear();
	GelAV.sheenRoughness = params.matGELAva.sheenRoughness;
	GelAV.iridescence = params.matGELAva.iridescence;
	GelAV.iridescenceIOR = params.matGELAva.iridescenceIOR;
	GelAV.iridescenceThicknessRange = [ 0, params.matGELAva.iridescenceThickness ];
	GelAV.specularColor.set( params.matGELAva.specularColor ).convertSRGBToLinear();
	GelAV.specularIntensity = params.matGELAva.specularIntensity;
	GelAV.transparent = GelAV.opacity < 1;
	GelAV.flatShading = params.matGELAva.flatShading;

	const m1bas = matcoquebas; //coque bas
	m1bas.color.set( params.matcoque.color ).convertSRGBToLinear();
	m1bas.transparent=0;
	m1bas.metalness = params.matcoque.metalness;
	m1bas.roughness = params.matcoque.roughness;
	m1bas.clearcoat = params.matcoque.clearcoat;
	m1bas.clearcoatRoughness = params.matcoque.clearcoatRoughness;
	m1bas.opacity=params.matcoquebas.opacity;
	m1bas.transparent = m1bas.opacity < 1;
	m1bas.ior= 1;
	m1bas.flatShading = params.matcoquebas.flatShading;
	
	const m1haut = matcoquehaut
	m1haut.color.set( params.matcoque.color ).convertSRGBToLinear();
	m1haut.metalness = params.matcoque.metalness;
	m1haut.roughness = params.matcoque.roughness;
	m1haut.clearcoat = params.matcoque.clearcoat;
	m1haut.ior= params.matcoque.ior;
	m1haut.clearcoatRoughness = params.matcoque.clearcoatRoughness;	

	m1haut.opacity=params.matcoquehaut.opacity;
	m1haut.transparent=m1haut.opacity < 1;
	
	m1haut.flatShading = params.matcoque.flatShading;

	const teckteck = teck;
	teckteck.map = params.teck.map; 

	const teckhaut = teckailehaut
	teckhaut.map = params.teck.map;
	teckhaut.transparent=0;
	teckhaut.opacity= params.matcoquehaut.opacity;
	teckhaut.transparent = m1haut.opacity < 1;
	teckhaut.ior= 1;

	const teckbas = teckailebas
	teckbas.map = params.teck.map;

	teckbas.opacity= params.matcoquebas.opacity;
	teckbas.transparent = m1bas.opacity < 1;
	teckbas.ior= 1;

	const m2 = ledmat;
	m2.color.set( params.ledmat.color ).convertSRGBToLinear();
	m2.emissive.set( params.ledmat.emissive ).convertSRGBToLinear();
	m2.emissiveIntensity = params.ledmat.emissiveIntensity;
	m2.opacity = params.ledmat.opacity;
	const ledSM = ledSMmat;
	ledSM.opacity = params.ledSMmat.opacity;
	//ledSM.emissiveIntensity

	const alubas = aluailebas;
	alubas.opacity=params.matcoquebas.opacity;
	alubas.transparent = m1bas.opacity < 1;
	const aluhaut = aluailehaut;
	aluhaut.opacity=params.matcoquehaut.opacity;
	aluhaut.transparent = m1haut.opacity < 1;

    

	if ( ptRenderer.samples < 1.0 || ! params.enable ) {

		renderer.render( scene, activeCamera );

	}
	floorPlane.material.color.set( params.floorColor ).convertSRGBToLinear();
	floorPlane.material.metalness = params.floorMetalness;
	floorPlane.material.roughness = params.floorRoughness;
	floorPlane.material.opacity = params.floorOpacity;
	if ( params.enable && delaySamples === 0 ) {

		const samples = Math.floor( ptRenderer.samples );
		samplesEl.innerText = `samples: ${ samples }`;

		ptRenderer.material.materials.updateFrom( sceneInfo.materials, sceneInfo.textures );
		ptRenderer.material.filterGlossyFactor = params.filterGlossyFactor;
		ptRenderer.material.environmentIntensity = params.environmentIntensity;
		ptRenderer.material.bounces = params.bounces;
		ptRenderer.material.physicalCamera.updateFrom( activeCamera );
		ptRenderer.material.setDefine( 'FEATURE_MIS', 0 );
		activeCamera.updateMatrixWorld();

		if ( ! params.pause || ptRenderer.samples < 1 ) {

			for ( let i = 0, l = params.samplesPerFrame; i < l; i ++ ) {

				ptRenderer.update();

			}

		}else{
			perspectiveCamera.position.set(1,0.25,0); 

			controls.target.set(0, 0, 0);
			controls.update();
		 scene.traverse(c=> {

		 					if (c.isMesh){console.log(c.name);}
							});
		}
		renderer.autoClear = false;
		fsQuad.render( renderer );
		renderer.autoClear = true;

	} else if ( delaySamples > 0 ) {

		delaySamples --;

	}

	samplesEl.innerText = `Samples: ${ Math.floor( ptRenderer.samples ) }`;

}

function resetRenderer() {

	if ( params.tilesX * params.tilesY !== 1.0 ) {

		delaySamples = 1;

	}

	ptRenderer.reset();

}

function onResize() {

	const w = window.innerWidth*0.5;
	const h = window.innerHeight*0.5;
	const scale = params.resolutionScale;
	const dpr = window.devicePixelRatio;


	ptRenderer.setSize( w * scale * dpr, h * scale * dpr );
	ptRenderer.reset();

	renderer.setSize( w, h );
	renderer.setPixelRatio( window.devicePixelRatio * scale );

	const aspect = w / h;
	perspectiveCamera.aspect = aspect;
	perspectiveCamera.updateProjectionMatrix();

	const orthoHeight = orthoWidth / aspect;
	orthoCamera.top = orthoHeight / 2;
	orthoCamera.bottom = orthoHeight / - 2;
	orthoCamera.updateProjectionMatrix();

}

function buildGui() {

	if ( gui ) {

		gui.destroy();

	}

	gui = new GUI();

	gui.add( params, 'model', Object.keys( models ) ).onChange( updateModel );

	const pathTracingFolder = gui.addFolder( 'path tracing' );
	pathTracingFolder.add( params, 'enable' );
	pathTracingFolder.add( params, 'pause' );
	pathTracingFolder.add( params, 'multipleImportanceSampling' ).onChange( v => {

		ptRenderer.material.setDefine( 'FEATURE_MIS', Number( v ) );
		ptRenderer.reset();

	} );
	pathTracingFolder.add( params, 'acesToneMapping' ).onChange( v => {

		renderer.toneMapping = v ? ACESFilmicToneMapping : NoToneMapping;

	} );
	pathTracingFolder.add( params, 'bounces', 1, 20, 1 ).onChange( () => {

		ptRenderer.reset();

	} );
	pathTracingFolder.add( params, 'filterGlossyFactor', 0, 1 ).onChange( () => {

		ptRenderer.reset();

	} );

	const resolutionFolder = gui.addFolder( 'resolution' );
	resolutionFolder.add( params, 'resolutionScale', 0.1, 1.0, 0.01 ).onChange( () => {

		onResize();

	} );
	resolutionFolder.add( params, 'samplesPerFrame', 1, 10, 1 );
	resolutionFolder.add( params, 'tilesX', 1, 10, 1 ).onChange( v => {

		ptRenderer.tiles.x = v;

	} );
	resolutionFolder.add( params, 'tilesY', 1, 10, 1 ).onChange( v => {

		ptRenderer.tiles.y = v;

	} );
	resolutionFolder.add( params, 'cameraProjection', [ 'Perspective', 'Orthographic' ] ).onChange( v => {

		updateCamera( v );

	} );
	resolutionFolder.open();

	const environmentFolder = gui.addFolder( 'environment' );
	environmentFolder.add( params, 'envMap', envMaps ).name( 'map' ).onChange( updateEnvMap );
	environmentFolder.add( params, 'environmentBlur', 0.0, 1.0 ).onChange( () => {

		updateEnvBlur();
		ptRenderer.reset();

	} ).name( 'env map blur' );
	environmentFolder.add( params, 'environmentIntensity', 0.0, 10.0 ).onChange( () => {

		ptRenderer.reset();

	} ).name( 'intensity' );
	environmentFolder.add( params, 'environmentRotation', 0, 2 * Math.PI ).onChange( v => {

		ptRenderer.material.environmentRotation.makeRotationY( v );
		ptRenderer.reset();

	} );
	environmentFolder.open();

	const backgroundFolder = gui.addFolder( 'background' );
	backgroundFolder.add( params, 'backgroundType', [ 'Environment', 'Gradient' ] ).onChange( v => {

		if ( v === 'Gradient' ) {

			scene.background = backgroundMap;
			ptRenderer.material.backgroundMap = backgroundMap;

		} else {

			scene.background = scene.environment;
			ptRenderer.material.backgroundMap = null;

		}

		ptRenderer.reset();

	} );
	backgroundFolder.addColor( params, 'bgGradientTop' ).onChange( v => {

		backgroundMap.topColor.set( v );
		backgroundMap.update();

		ptRenderer.reset();

	} );
	backgroundFolder.addColor( params, 'bgGradientBottom' ).onChange( v => {

		backgroundMap.bottomColor.set( v );
		backgroundMap.update();

		ptRenderer.reset();

	} );
	backgroundFolder.add( params, 'backgroundAlpha', 0, 1 ).onChange( v => {

		ptRenderer.material.backgroundAlpha = v;
		ptRenderer.reset();

	} );
	backgroundFolder.add( params, 'checkerboardTransparency' ).onChange( v => {

		if ( v ) document.body.classList.add( 'checkerboard' );
		else document.body.classList.remove( 'checkerboard' );

	} );

	const floorFolder = gui.addFolder( 'floor' );
	floorFolder.addColor( params, 'floorColor' ).onChange( () => {

		ptRenderer.reset();

	} );
	floorFolder.add( params, 'floorRoughness', 0, 1 ).onChange( () => {

		ptRenderer.reset();

	} );
	floorFolder.add( params, 'floorMetalness', 0, 1 ).onChange( () => {

		ptRenderer.reset();

	} );
	floorFolder.add( params, 'floorOpacity', 0, 1 ).onChange( () => {

		ptRenderer.reset();

	} );
	//floorFolder.close();

	const matFolder1 = gui.addFolder( 'Coque' );
	matFolder1.addColor( params.matcoque, 'color' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.addColor( params.matcoque, 'emissive' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'emissiveIntensity', 0.0, 50.0, 0.01 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'roughness', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'metalness', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'opacity', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'transmission', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'thinFilm', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'attenuationDistance', 0.05, 2.0 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.addColor( params.matcoque, 'attenuationColor' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'ior', 0.9, 3.0 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'clearcoat', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'clearcoatRoughness', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.addColor( params.matcoque, 'sheenColor' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'sheenRoughness', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'iridescence', 0.0, 1.0 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'iridescenceIOR', 0.1, 3.0 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'iridescenceThickness', 0.0, 1200.0 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.addColor( params.matcoque, 'specularColor' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'specularIntensity', 0.0, 1.0 ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'matte' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'flatShading' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.add( params.matcoque, 'castShadow' ).onChange( () => {ptRenderer.reset();} );
	matFolder1.close();
	const matFolder2 = gui.addFolder( 'LED' );
	matFolder2.addColor( params.ledmat, 'color' ).onChange( () => {ptRenderer.reset();} );
	matFolder2.addColor( params.ledmat, 'emissive' ).onChange( () => {ptRenderer.reset();} );
	matFolder2.add( params.ledmat, 'emissiveIntensity', 0.0,5.0, 0.01 ).onChange( () => {ptRenderer.reset();} );
	
	matFolder2.close();
	const matFolder3 = gui.addFolder( 'Coque bas' );
	matFolder3.add( params.matcoquebas, 'opacity', 0, 1 ).onChange( () => {ptRenderer.reset();} );
	matFolder3.add( params, 'ouvert' ).onChange( v => {

		if ( v ){params.matcoquebas.opacity=1;
				params.matcoquehaut.opacity=0;
		ptRenderer.reset();}
		else
			{params.matcoquebas.opacity=0;
			params.matcoquehaut.opacity=1;ptRenderer.reset();}

	} );
	gui.destroy();
}
////////////////////////////////////////////////////////////////BOUTON HTML



/////////////////////////bouton Couleur

const Couleur2 = document.querySelector('#Couleurbtn');//gere le contenair des couleurs

Couleur2.addEventListener('click', () => {
  
  //$('#BandeCouleur').fadeIn();
  controls.update();
 
});
/////////////////////////bouton Sellerie

const Selleriebtn = document.querySelector('#Sellerie');//gere le contenair des couleurs

Selleriebtn.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.2735, 0.07545, 0.0558);//x,y,z init camera 
 controls.target.set(-0.131, 0.04363, 0.02378);
 controls.update();
 
});
/////////////////////////bouton TECK

const Teckbtn = document.querySelector('#Teck');//gere le contenair des couleurs

Teckbtn.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.405499, 0.0603818, 0.091348);//x,y,z init camera 
 controls.target.set(0, 0, 0.10);
 controls.update();

});
/////////////////////////bouton Environ

const Environbtn = document.querySelector('#environ');//gere le contenair des couleurs

Environbtn.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.845344, 0.99546, 1.36798);//x,y,z init camera 
 controls.target.set(0, 0, 0.10);
 controls.update();
 
});
///////////////////////////////////////////////////////////////////////////////////////// PEINTURE
const Couleurcoque = document.querySelector('#coque');//BANDEAU couleur coque

Couleurcoque.addEventListener('click', () => {
 perspectiveCamera.position.set( 0.69, -0.0134, 0.9220577);//x,y,z init camera 
 controls.target.set(0.12609, 0.0134101, -0.01535529);
 controls.update();
 Peinture=0;
  //$('#BandeBasCoque').fadeIn();
   });


const gelcoat = document.querySelector('#metal');//gere les camera//couleur rouge
gelcoat.addEventListener('click', () => {
	if(metal==0){
	gelcoat.innerHTML="GelCoat";
	params.matcoque.metalness = 0;
  ptRenderer.reset();
			metal=1;}else{
	gelcoat.innerHTML="Metal";
	params.matcoque.metalness = 0.8;
  ptRenderer.reset();
	metal=0;}
  
});

const Coquerouge = document.querySelector('#red');//gere les camera//couleur rouge
Coquerouge.addEventListener('click', () => {
  //perspectiveCamera.position.set(0,0.5,0.00);
  //controls.target.set(0, 0, 0.00);
  //controls.update();
	color = "#8b0000";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
	
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coqueorange = document.querySelector('#orange');//gere les camera//couleur rouge
Coqueorange.addEventListener('click', () => {
  //perspectiveCamera.position.set(0,0.5,0.00);
  //controls.target.set(0, 0, 0.00);
  //controls.update();
	color = "#ff4500";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coquejaune = document.querySelector('#jaune');//gere les camera//couleur rouge
Coquejaune.addEventListener('click', () => {
  //perspectiveCamera.position.set(0,0.5,0.00);
  //controls.target.set(0, 0, 0.00);
  //controls.update();
	color = "#ffd700";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coquevert = document.querySelector('#vert');//gere les camera//couleur rouge
Coquevert.addEventListener('click', () => {
  //perspectiveCamera.position.set(0,0.5,0.00);
  //controls.target.set(0, 0, 0.00);
  //controls.update();
	
	color = "#004225";
  var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coquebleu = document.querySelector('#bleu');//gere les camera//couleur rouge
Coquebleu.addEventListener('click', () => {
 color = "#0d98ba";
 var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coqueblanc = document.querySelector('#blanc');//gere les camera//couleur rouge
Coqueblanc.addEventListener('click', () => {
	color = "#fffafa";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coquegris = document.querySelector('#gris');//gere les camera//couleur rouge
Coquegris.addEventListener('click', () => {
 
	color = "#757575";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coquegrisolive = document.querySelector('#grisolive');//gere les camera//couleur rouge
Coquegrisolive.addEventListener('click', () => {
  
	color = "#3b3c36";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});
const Coquenoir = document.querySelector('#noir');//gere les camera//couleur rouge
Coquenoir.addEventListener('click', () => {
 
	color = "#1b1b1b";
	var div = document.getElementById('bandeMainmenu');
      div.style.backgroundColor=color;
  couleurMemoire = color;
  if (Peinture==0) {params.matcoque.color= color;}
  if (Peinture==1) {params.matGELARR.color=color;}
  if (Peinture==2) {params.matGELAva.color=color;}
  ptRenderer.reset();
  
});

///////////////////////////////////////////////////////////////////////////////////////// STRUCTURE ARRIERE

const GELCOATARR = document.querySelector('#GelCoatARR');//BANDEAU couleur coque

GELCOATARR.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.7284, 0.393234, -0.37846490);//x,y,z init camera 
 controls.target.set(0, 0, 0.00);
 controls.update();
 Peinture=1;
 });

///////////////////////////////////////////////////////////////////////////////////////// STRUCTURE AVANT

const GELCOATAVA = document.querySelector('#GelCoatAva');//BANDEAU couleur coque

GELCOATAVA.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.42182, 0.3064635, 0.00);//x,y,z init camera 
 controls.target.set(0.10, 0, 0.00);
 controls.update();
 Peinture=2;
 });
/////////////////////////////////////////////////////////////////////////////////////////// SELLERI3

/*const selleriebtn = document.querySelector('#Sellerie');//gere les textures sellerie
selleriebtn.addEventListener('click', () => {

});*/
const selleriebtn0 = document.querySelector('#SUN');//gere les textures sellerie
selleriebtn0.addEventListener('click', () => {
  
  params.sellerieindigo.map= texSellerie;
  params.sellerieindigo.color='#e79f67';
  ptRenderer.reset();

});
const selleriebtn1 = document.querySelector('#INDIGO');//gere les textures sellerie
selleriebtn1.addEventListener('click', () => {
  
  params.sellerieindigo.map= texINDIGO;
  params.sellerieindigo.color='#3d767e';
  ptRenderer.reset();

});
const selleriebtn2 = document.querySelector('#SNOW');//gere les textures sellerie
selleriebtn2.addEventListener('click', () => {
  
  params.sellerieindigo.map= texSNOW;
  params.sellerieindigo.color='#e9e7d8';
  ptRenderer.reset();

});
const selleriebtn3 = document.querySelector('#TAUPE');//gere les textures sellerie
selleriebtn3.addEventListener('click', () => {
  
  params.sellerieindigo.map= texTAUPE;
  params.sellerieindigo.color='#cfbc90';
  ptRenderer.reset();

});
const selleriebtn4 = document.querySelector('#TONI');//gere les textures sellerie
selleriebtn4.addEventListener('click', () => {
  
  params.sellerieindigo.map= texTONI;
  params.sellerieindigo.color='#ada5a5';
  ptRenderer.reset();

});
const selleriebtn5 = document.querySelector('#LAVA');//gere les textures sellerie
selleriebtn5.addEventListener('click', () => {
  
  params.sellerieindigo.map= texLAVA;
  params.sellerieindigo.color='#903b3b';
  ptRenderer.reset();

});
const selleriebtn6 = document.querySelector('#CARBON');//gere les textures sellerie
selleriebtn6.addEventListener('click', () => {
  
  params.sellerieindigo.map= texCARBON;
  params.sellerieindigo.color='#AFAFAF';
  ptRenderer.reset();
});

///////////////////////////////////////////////TECK

const teckGrisBlancbtn = document.querySelector('#GrisBlanc');
teckGrisBlancbtn.addEventListener('click', () => {
  params.teck.map=teckGrisBlanc;
  ptRenderer.reset();
  
});

const teckTeckBlancbtn = document.querySelector('#TeckBlanc');
teckTeckBlancbtn.addEventListener('click', () => {
  params.teck.map=teckTeckBlanc;
  ptRenderer.reset();
  
});

const teckv1noirbtn = document.querySelector('#V1noir');
teckv1noirbtn.addEventListener('click', () => {
  params.teck.map=teckv1noir;
  ptRenderer.reset();
  
});

const teckGrisnoirbtn = document.querySelector('#GrisNoir');
teckGrisnoirbtn.addEventListener('click', () => {
  params.teck.map=teckGrisNoir;
  ptRenderer.reset();
  
});

const nuithdr = document.querySelector('#nuit');//gere les maps d'environnement

nuithdr.addEventListener('click', () => {
  params.envMap = envMaps.Nuit;updateEnvMap();
 // setTimeout(() => {
//					updateEnvMap();}, 400);
});
const Criquehdr = document.querySelector('#Crique');//gere les maps d'environnement

Criquehdr.addEventListener('click', () => {
  params.envMap = envMaps.Crique;updateEnvMap();
 // setTimeout(() => {
//					updateEnvMap();}, 400);
});
const Ocean = document.querySelector('#ocean');//gere les maps d'environnement

Ocean.addEventListener('click', () => {
  params.envMap = envMaps.Oceanflo;updateEnvMap();
 // setTimeout(() => {
//					updateEnvMap();}, 400);
});
const Iles = document.querySelector('#Iles');//gere les maps d'environnement

Iles.addEventListener('click', () => {
  params.envMap = envMaps.Small;updateEnvMap();
 // setTimeout(() => {
//					updateEnvMap();}, 400);
});
const Rock = document.querySelector('#montain');//gere les maps d'environnement

Rock.addEventListener('click', () => {
  params.envMap = envMaps.Rock;updateEnvMap();
 // setTimeout(() => {
//					updateEnvMap();}, 400);
});//BordOcean
const bord = document.querySelector('#bordOcean');//gere les maps d'environnement

bord.addEventListener('click', () => {
  params.envMap = envMaps.BordOcean;updateEnvMap();
 // setTimeout(() => {
//					updateEnvMap();}, 400);
});
const snow2 = document.querySelector('#snowENV');//gere les maps d'environnement

snow2.addEventListener('click', () => {
  params.envMap = envMaps.snow2;updateEnvMap();
 });
const indoor = document.querySelector('#indoor');//gere les maps d'environnement
indoor.addEventListener('click', () => {
  params.envMap = envMaps.chantier;updateEnvMap();
 });
///////////////////////////Environement
var slider = document.getElementById("EnvironIntensity");
var output = document.getElementById("IntensityValue");
slider.oninput = function() {
  params.environmentIntensity = this.value;
  output.innerHTML = this.value;
  ptRenderer.reset();
}
params.environmentIntensity = slider.value;

var sliderrota = document.getElementById("EnvironRota");
var outputrota = document.getElementById("RotaValue");
sliderrota.oninput = function() {
  ptRenderer.material.environmentRotation.makeRotationY( this.value );
  outputrota.innerHTML = this.value;
  ptRenderer.reset();
}


var sliderflou = document.getElementById("Environflou");
var outputflou = document.getElementById("flouValue");
sliderflou.oninput = function() {
  params.environmentBlur= this.value;
  outputflou.innerHTML = this.value;
  updateEnvBlur();
  ptRenderer.reset();
}
params.environmentBlur = sliderflou.value;


////////////////////////////////

const ledbtn = document.querySelector('#ledbtn');//gere les LEDS
ledbtn.addEventListener('click', () => {
	if(ledall==0){
		ledbtn.innerHTML="led OFF";
  params.ledmat.emissiveIntensity =  0.0; 
  params.ledSMmat.opacity = 0;
  params.ledmat.opacity=0;
  ptRenderer.reset();
  ledall=1;
}

  	else{
  		ledbtn.innerHTML="led ON";
  params.ledmat.emissiveIntensity =  5.0; 
  params.ledmat.opacity=1;
  params.ledSMmat.opacity = 1;
  ptRenderer.reset();
  ledall=0;
}
});
//////////////////////////////////////////////////////////MODE EXPERT / UTILISATEUR

const fullbtn = document.querySelector('#full');// MODE MUSCLE
fullbtn.addEventListener('click', () => {
if (fullscreenADN==0){
const w = window.innerWidth*1;
	const h = window.innerHeight*0.9;
	const scale = params.resolutionScale;
	const dpr = window.devicePixelRatio;


	ptRenderer.setSize( w * scale * dpr, h * scale * dpr );
	ptRenderer.reset();

	renderer.setSize( w, h );
	renderer.setPixelRatio( window.devicePixelRatio * scale );

	const aspect = w / h;
	perspectiveCamera.aspect = aspect;
	perspectiveCamera.updateProjectionMatrix();

	const orthoHeight = orthoWidth / aspect;
	orthoCamera.top = orthoHeight / 2;
	orthoCamera.bottom = orthoHeight / - 2;
	orthoCamera.updateProjectionMatrix();
	var div2 = document.getElementById('container');
	 div2.style.width = "100%";
	 div2.style.height = "95%";
	 div2.style.top = "5%";
      //div2.style.backgroundColor=color;
	 var divleft = document.getElementById('BandeLEFTVUE');
	 divleft.style.right = "0%";

	 let divbas = document.getElementById('BandeBasCoque');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbas.style.top="90%";
	 let divbasDiam = document.getElementById('BandeBasDiamente');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbasDiam.style.top="90%";
	 let divbasteck = document.getElementById('BandeBasTeck');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbasteck.style.top="90%";
	 let divbasenviron = document.getElementById('BandeBasEnviron');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbasenviron.style.top="95%";

	 fullscreenADN = 1;
	} else {////////////////////////////////////MODE NORMAL

	const w = window.innerWidth*0.5;
	const h = window.innerHeight*0.5;
	const scale = params.resolutionScale;
	const dpr = window.devicePixelRatio;
	ptRenderer.setSize( w * scale * dpr, h * scale * dpr );
	ptRenderer.reset();
	renderer.setSize( w, h );
	renderer.setPixelRatio( window.devicePixelRatio * scale );
	const aspect = w / h;
	perspectiveCamera.aspect = aspect;
	perspectiveCamera.updateProjectionMatrix();
	const orthoHeight = orthoWidth / aspect;
	orthoCamera.top = orthoHeight / 2;
	orthoCamera.bottom = orthoHeight / - 2;
	orthoCamera.updateProjectionMatrix();
	var div3 = document.getElementById('container');
	 div3.style.width = "50%";
	 div3.style.height = "50%";
	 div3.style.top = "20%";
	let divbas2 = document.getElementById('BandeBasCoque');
	 //divbas2.style.webkitTransform = 'rotate('+0+'deg)';
	 divbas2.style.top="80%";
	 let divbasDiam = document.getElementById('BandeBasDiamente');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbasDiam.style.top="80%";
	 let divbasteck = document.getElementById('BandeBasTeck');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbasteck.style.top="80%";
	
      //div2.style.backgroundColor=color;
	 var divleft2 = document.getElementById('BandeLEFTVUE');
	 divleft2.style.right = "50%";
	 fullscreenADN = 0;
	 let divbasenviron = document.getElementById('BandeBasEnviron');
	 //divbas.style.webkitTransform = 'rotate('+270+'deg)'; 
	 divbasenviron.style.top="80%";
	}


   
  

//////////////////////////////////////////////////////////
});

//////////////////////////////////// CAMERA
const CamTop = document.querySelector('#top');//gere les maps d'environnement
CamTop.addEventListener('click', () => {
 perspectiveCamera.position.set( 0.10, 1.11, 0.0125);//x,y,z init camera 
 controls.target.set(0.1, 0, 0.0125);
 controls.update();
});
const Camface = document.querySelector('#face');//gere les maps d'environnement
Camface.addEventListener('click', () => {
 perspectiveCamera.position.set( 0.84, 0.052, 0.0125);//x,y,z init camera 
 controls.target.set(0.09, 0.01, 0.0125);
 controls.update();
});
const CamArr = document.querySelector('#arriere');//gere les maps d'environnement
CamArr.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.75, 0.177, 0.0125);//x,y,z init camera 
 controls.target.set(0.0, -0.011, 0.0125);
 controls.update();
});
const Cam34AV = document.querySelector('#TroisQuartAv');//gere les maps d'environnement
Cam34AV.addEventListener('click', () => {
 perspectiveCamera.position.set( 0.72, 0.048, 0.57);//x,y,z init camera 
 controls.target.set(0.13, 0.01, -0.05);
 controls.update();
});
const Cam34AR = document.querySelector('#TroisQuartAr');//gere les maps d'environnement
Cam34AR.addEventListener('click', () => {
 perspectiveCamera.position.set( -0.64, 0.228, 0.457);//x,y,z init camera 
 controls.target.set(0.00, -0.01, 0.0195);
 controls.update();
});



const open = document.querySelector('#ouverture');//gere les maps d'environnement
open.addEventListener('click', () => {
 

if ( ouvre==0 ){params.matcoquebas.opacity=1;
				params.matcoquehaut.opacity=0;
		ptRenderer.reset();
		ouvre=1;}
		else
			{params.matcoquebas.opacity=0;
			params.matcoquehaut.opacity=1;ptRenderer.reset();
			ouvre=0;}
});
/*const coque = document.querySelector('#COQUE2');//gere la couleur de la coque
coque.addEventListener('input', function() { 
 params.matcoque.color=coque.value;
 ptRenderer.reset();
console.log(coque.value);
});*/

function updateEnvMap() {

	new RGBELoader()
		.load( params.envMap, texture => {

			if ( scene.environmentMap ) {

				scene.environment.dispose();
				envMap.dispose();

			}

			envMap = texture;
			updateEnvBlur();
			ptRenderer.reset();

		} );

}

function updateEnvBlur() {

	const blurredEnvMap = envMapGenerator.generate( envMap, params.environmentBlur );
	ptRenderer.material.envMapInfo.updateFrom( blurredEnvMap );

	scene.environment = blurredEnvMap;
	if ( params.backgroundType !== 'Gradient' ) {

		scene.background = blurredEnvMap;

	}

}

function updateCamera( cameraProjection ) {

	if ( cameraProjection === 'Perspective' ) {

		if ( activeCamera ) {

			perspectiveCamera.position.copy( activeCamera.position );

		}

		activeCamera = perspectiveCamera;

	} else {

		if ( activeCamera ) {

			orthoCamera.position.copy( activeCamera.position );

		}

		activeCamera = orthoCamera;

	}

	controls.object = activeCamera;
	ptRenderer.camera = activeCamera;

	controls.update();

	resetRenderer();

}

function convertOpacityToTransmission( model ) {

	model.traverse( c => {

		if ( c.material ) {

			const material = c.material;
			if ( material.opacity < 0.65 && material.opacity > 0.2 ) {

				const newMaterial = new MeshPhysicalMaterial();
				for ( const key in material ) {

					if ( key in material ) {

						if ( material[ key ] === null ) {

							continue;

						}

						if ( material[ key ].isTexture ) {

							newMaterial[ key ] = material[ key ];

						} else if ( material[ key ].copy && material[ key ].constructor === newMaterial[ key ].constructor ) {

							newMaterial[ key ].copy( material[ key ] );

						} else if ( ( typeof material[ key ] ) === 'number' ) {

							newMaterial[ key ] = material[ key ];

						}

					}

				}

				newMaterial.opacity = 1.0;
				newMaterial.transmission = 1.0;
				c.material = newMaterial;

			}	
						
		}

	} );

}

async function updateModel() {

	if ( gui ) {

		document.body.classList.remove( 'checkerboard' );
		gui.destroy();
		gui = null;

	}

	
	const manager = new LoadingManager();
	const modelInfo = models[ params.model ];

	loadingModel = true;
	renderer.domElement.style.visibility = 'hidden';
	samplesEl.innerText = '--';
	creditEl.innerText = '--';
	loadingEl.innerText = 'Loading';
	loadingEl.style.visibility = 'visible';

	scene.traverse( c => {

		if ( c.material ) {

			const material = c.material;
			for ( const key in material ) {

				if ( material[ key ] && material[ key ].isTexture ) {

					material[ key ].dispose();

				}

			}

		}

	} );

	if ( sceneInfo ) {

		scene.remove( sceneInfo.scene );

	}


	const onFinish = async () => {

		if ( modelInfo.removeEmission ) {

			model.traverse( c => {

				if ( c.material ) {

					c.material.emissiveMap = null;
					c.material.emissiveIntensity = 0;

				}

			} );

		}

		if ( modelInfo.opacityToTransmission ) {

			convertOpacityToTransmission( model );

		}

		model.traverse( c => {

			if ( c.material ) {
				
				
				// set the thickness so we render the material as a volumetric object
				c.material.thickness = 1.0;
				
				c.material.side = DoubleSide;}

			
		} );

		if ( modelInfo.postProcess ) {

			modelInfo.postProcess( model );

		}

		// rotate model after so it doesn't affect the bounding sphere scale
		if ( modelInfo.rotation ) {

			model.rotation.set( ...modelInfo.rotation );

		}

		// center the model
		const box = new Box3();
		box.setFromObject( model );
		model.position
			.addScaledVector( box.min, - 0.5 )
			.addScaledVector( box.max, - 0.5 );

		const sphere = new Sphere();
		box.getBoundingSphere( sphere );

		model.scale.setScalar( 1 / sphere.radius );
		model.position.multiplyScalar( 1 / sphere.radius );

		box.setFromObject( model );

		model.updateMatrixWorld();

		
		floorPlane.position.y = -0.055;
		group.add( model, floorPlane );

		const reducer = new MaterialReducer();
		reducer.process( group );

		const generator = new PathTracingSceneWorker();
		const result = await generator.generate( group, { onProgress: v => {

			const percent = Math.floor( 100 * v );
			loadingEl.innerText = `CONSTRUCTION DU MODEL : ${ percent }%`;

		} } );

		

		sceneInfo = result;
		scene.add( sceneInfo.scene );
		resultFLO = result;
		const { bvh, textures, materials } = result;
		const geometry = bvh.geometry;
		const material = ptRenderer.material;

		material.bvh.updateFrom( bvh );
		material.attributesArray.updateFrom(
			geometry.attributes.normal,
			geometry.attributes.tangent,
			geometry.attributes.uv,
			geometry.attributes.color,
		);
		material.materialIndexAttribute.updateFrom( geometry.attributes.materialIndex );
		material.textures.setTextures( renderer, 2048, 2048, textures );
		material.materials.updateFrom( materials, textures );

		generator.dispose();

		loadingEl.style.visibility = 'hidden';

		creditEl.innerHTML = modelInfo.credit || '';
		creditEl.style.visibility = modelInfo.credit ? 'visible' : 'hidden';
		params.bounces = modelInfo.bounces || 3;
		params.floorColor = modelInfo.floorColor || '#00DDFF';
		params.floorRoughness = modelInfo.floorRoughness || 0.1;
		params.floorMetalness = modelInfo.floorMetalness || 0.1;
		params.bgGradientTop = modelInfo.gradientTop || '#111111';
		params.bgGradientBottom = modelInfo.gradientBot || '#000000';

		
		backgroundMap.topColor.set( params.bgGradientTop );
		backgroundMap.bottomColor.set( params.bgGradientBottom );
		backgroundMap.update();

		params.ledmat.color =  '#fff8c7';
		params.ledmat.emissive = '#fff8c7';
		params.ledmat.emissiveIntensity =  0; 

		scene.background = scene.environment;
		ptRenderer.material.backgroundMap = null;

		buildGui();

		loadingModel = false;
		renderer.domElement.style.visibility = 'visible';
		if ( params.checkerboardTransparency ) {

			document.body.classList.add( 'checkerboard' );

		}

		ptRenderer.reset();

	};
	

	const url = modelInfo.url;
	if ( /(gltf|glb)$/i.test( url ) ) {

		manager.onLoad = onFinish;
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath('/ASVs11/draco/');
		


		const loader = new GLTFLoader(manager);
		loader.setDRACOLoader(dracoLoader);
		//new GLTFLoader( manager )
			
			loader.setMeshoptDecoder( MeshoptDecoder );
				
			loader.load(
				url,
				gltf => {

					model = gltf.scene;
					group.add(gltf.scene);
					
						
					model.traverse( c=> {if (c.material){ ///////////gestion des materiaux
		
						if (c.material.name==='led'){console.log(c.material.name);c.material= ledmat;c.material.name='led';}
						if (c.material.name==='ledSM'){console.log(c.material);c.material= ledSMmat;c.material.name='ledSM_FLO';console.log(c.material);}
						if(c.material.name==='coque'){console.log(c.material.name);c.material= matcoque;c.material.name='coque';}
						if(c.material.name==='GelCoatArr'){console.log(c.material.name);c.material= matGELARR;c.material.name='GelCoatArr';}
						if(c.material.name==='GelCoatAva'){console.log(c.material.name);c.material= matGELAva;c.material.name='GelCoatAva';}

						
						if(c.material.name==='sellerie'){console.log(c.material.name);c.material= sellerieindigo;c.material.name='sellerie';}
						if(c.material.name==='teck'){console.log(c.material.name); c.material = teck; c.material.name='teck';}
						///gestion des animations avec l'alpha en bas
						if(c.material.name==='coquealiebas'){console.log(c.material.name);c.material=matcoquebas; c.material.name='coqueailebas';}
						if(c.material.name==='teckailebas'){console.log(c.material.name);c.material=teckailebas;c.material.name='teckailebas';}
						if(c.material.name==='aluailebas'){console.log(c.material.name);c.material=aluailebas;c.material.name='aluailebas';}
						///gestion des animations avec l'alpha en haut
						if(c.material.name==='coquealiehaut'){console.log(c.material.name);c.material=matcoquehaut; c.material.name='coqueailehaut';}
						if(c.material.name==='teckailehaut'){console.log(c.material.name);c.material=teckailehaut;c.material.name='teckailehaut';}
						if(c.material.name==='aluailehaut'){console.log(c.material.name);c.material=aluailehaut;c.material.name='aluailehaut';}
						if(c.material.name==='metal noir'){console.log(c.material.name);c.material.side = DoubleSide;c.material.name='metal noir';}


						}
						
					});

					
					
					},
				progress => {

					if ( progress.total !== 0 && progress.total >= progress.loaded ) {

						const percent = Math.floor( 100 * progress.loaded / progress.total );
						loadingEl.innerText = `PATIENTEZ : ${ percent }%`;

						// add floor
					}
					
						
					},



			);
			///////////////////////////////////////////Ajout des materiaux a la scene pour init
				const DiamentINDIGO = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texINDIGO,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry0 = new THREE.PlaneGeometry( 0, 0 );
					  geometry0.material = DiamentINDIGO;

				const plane0 = new THREE.Mesh( geometry0, DiamentINDIGO );
				group.add( plane0 );

				const DiamentSUN = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texSellerie,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry = new THREE.PlaneGeometry( 0, 0 );
					  geometry.material = DiamentSUN;

				const plane = new THREE.Mesh( geometry, DiamentSUN );
				group.add( plane );
				////////////////////////
				const DiamentSNOW = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texSNOW,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry2 = new THREE.PlaneGeometry( 0, 0 );
					  geometry2.material = DiamentSNOW;
				const plane2 = new THREE.Mesh( geometry2, DiamentSNOW );
				group.add( plane2 );
				/////////////////////
				const DiamentTAUPE = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texTAUPE,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry3 = new THREE.PlaneGeometry( 0, 0 );
					  geometry3.material = DiamentTAUPE;
				const plane3 = new THREE.Mesh( geometry3, DiamentTAUPE );
				group.add( plane3 );
				/////////////////////
				const DiamentTONI = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texTONI,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry4 = new THREE.PlaneGeometry( 0, 0 );
					  geometry4.material = DiamentTONI;
				const plane4 = new THREE.Mesh( geometry4, DiamentTONI );
				group.add( plane4 );
				/////////////////////
				const DiamentLAVA = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texLAVA,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry5 = new THREE.PlaneGeometry( 0, 0 );
					  geometry5.material = DiamentLAVA;
				const plane5= new THREE.Mesh( geometry5, DiamentLAVA );
				group.add( plane5 );
				/////////////////////
				const DiamentCARBON = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : texCARBON,
						normalMap : texSellerieNor,
					    roughness : 0.9,
					
				});
				const geometry6 = new THREE.PlaneGeometry( 0, 0 );
					  geometry6.material = DiamentCARBON;
				const plane6 = new THREE.Mesh( geometry6, DiamentCARBON );
				group.add( plane6 );
				/////////////////////
				const teckgrisblancmat = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : teckGrisBlanc,
						normalMap : norteck,
					    roughness : 0.9,
					
				});
				const geometry7 = new THREE.PlaneGeometry( 0, 0 );
					  geometry7.material = teckgrisblancmat;
				const plane7 = new THREE.Mesh( geometry7, teckgrisblancmat );
				group.add( plane7 );
				/////////////////////
				const teckTeckblancmat = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : teckTeckBlanc,
						normalMap : norteck,
					    roughness : 0.9,
					
				});
				const geometry8 = new THREE.PlaneGeometry( 0, 0 );
					  geometry8.material = teckTeckblancmat;
				const plane8 = new THREE.Mesh( geometry8, teckTeckblancmat );
				group.add( plane8 );
				/////////////////////
				const teckV1noirmat = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : teckv1noir,
						normalMap : norteck,
					    roughness : 0.9,
					
				});
				const geometry9 = new THREE.PlaneGeometry( 0, 0 );
					  geometry9.material = teckV1noirmat;
				const plane9 = new THREE.Mesh( geometry9, teckV1noirmat );
				group.add( plane9 );
				/////////////////////
				
				const teckGrisNoirmat = new THREE.MeshPhysicalMaterial({///////////creer un plan pour init
						map : teckGrisNoir,
						normalMap : norteck,
					    roughness : 0.9,
					
				});
				const geometry10 = new THREE.PlaneGeometry( 0, 0 );
					  geometry10.material = teckGrisNoirmat;
				const plane10 = new THREE.Mesh( geometry9, teckGrisNoirmat );
				group.add( plane10 );
				/////////////////////init des leds
			const ledbtn2 = document.querySelector('#ledbtn');
			ledbtn2.innerHTML="led OFF";
 			params.ledmat.emissiveIntensity = 0; 
 			params.ledSMmat.opacity = 0;
 			params.sellerieindigo.map= texSellerie;
      params.sellerieindigo.color='#e79f67';
      ptRenderer.material.environmentRotation.makeRotationY( 1.3 );
      updateEnvMap();
      params.resolutionScale = 1;
 			ptRenderer.reset();
 			ledall=1;


	} else if ( /mpd$/i.test( url ) ) {

		manager.onProgress = ( url, loaded, total ) => {

			const percent = Math.floor( 100 * loaded / total );
			loadingEl.innerText = `Loading : ${ percent }%`;

		};

		const loader = new LDrawLoader( manager );
		await loader.preloadMaterials( 'https://raw.githubusercontent.com/gkjohnson/ldraw-parts-library/master/colors/ldcfgalt.ldr' );
		loader
			.setPartsLibraryPath( 'https://raw.githubusercontent.com/gkjohnson/ldraw-parts-library/master/complete/ldraw/' )
			.load(
				url,
				result => {

					model = LDrawUtils.mergeObject( result );
					model.rotation.set( Math.PI, 0, 0 );
					model.traverse( c => {
					
						if ( c.isLineSegments ) {

							c.visible = false;

						}

						if ( c.isMesh ) {

							c.material.roughness *= 0.25;
							
						}

					} );

					onFinish();

				},

			);


	}


}

///////////////////////////////////////////////////////////////////////////////////////////////
/*

function updateColor(){
    var col = hexToRgb(couleurMemoire);
    var sat = Number(masaturation)/100;
    var gray = col.r * 0.3086 + col.g * 0.6094 + col.b * 0.0820;

    col.r = Math.round(col.r * sat + gray * (1-sat));
    col.g = Math.round(col.g * sat + gray * (1-sat));
    col.b = Math.round(col.b * sat + gray * (1-sat));

   out = rgbToHex(col.r,col.g,col.b);
    
   // $('#output').val(out);
    
   // $('body').css("background",out);
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function initColorPicker() {
  var canvas = document.getElementById('colorCanvas');
  var canvasContext = canvas.getContext('2d');

  let gradient = canvas.getContext('2d').createLinearGradient(0, 0, canvas.width, 0)
  gradient.addColorStop(0, '#ff0000')
  gradient.addColorStop(1 / 6, '#ffff00')
  gradient.addColorStop((1 / 6) * 2, '#00ff00')
  gradient.addColorStop((1 / 6) * 3, '#00ffff')
  gradient.addColorStop((1 / 6) * 4, '#0000ff')
  gradient.addColorStop((1 / 6) * 5, '#ff00ff')
  gradient.addColorStop(1, '#ff0000')
  canvas.getContext('2d').fillStyle = gradient
  canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height)

  gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, canvas.height)
  gradient.addColorStop(0, 'rgba(255, 255, 255, 1)')
  gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)')
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')
  canvas.getContext('2d').fillStyle = gradient
  canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height)

  gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, canvas.height)
  gradient.addColorStop(0, 'rgba(0, 0, 0, 0)')
  gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0)')
  gradient.addColorStop(1, 'rgba(0, 0, 0, 1)')
  canvas.getContext('2d').fillStyle = gradient
  canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height)


   
    canvas.onclick = function(e) {
  	//console.log()
    var imgData = canvasContext.getImageData((e.offsetX / canvas.clientWidth) * canvas.width, (e.offsetY / canvas.clientHeight) * canvas.height, 1, 1);
    var rgba = imgData.data;
    var colorpicker = "rgba(" + rgba[0] + ", " + rgba[1] + ", " + rgba[2] + ", " + rgba[3] + ")";
     if (Peinture==0) {params.matcoque.color= colorpicker;}
 		 if (Peinture==1) {params.matGELARR.color=colorpicker;}
 		 if (Peinture==2) {params.matGELAva.color=colorpicker;}
  ptRenderer.reset();
    //console.log("%c" + color, "color:" + color)
  }
}

initColorPicker()
*/
